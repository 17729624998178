import api from '@/api/index'

export default {
  getAll: async payload => {
    const response = await api.budget.getAll(payload)
    const { data } = response
    return data
  },
  get: async payload => {
    const response = await api.budget.get(payload)
    const { data } = response
    return data
  },
  set: async payload => {
    const response = await api.budget.set(payload)
    return response
  },
  sendEmail: async payload => {
    const response = await api.budget.sendInvoice(payload)
    return response
  },
  acceptBudget: async payload => {
    const response = await api.budget.acceptBudget(payload)
    return response
  },
  changeStatusBudget: async payload => {
    const response = await api.budget.changeStatusBudget(payload)
    return response
  },
  sendBudget: async payload => {
    const response = await api.budget.sendBudget(payload)
    return response
  },
  printBudget: async payload => {
    const response = await api.budget.printBudget(payload)
    return response
  },
  deleteBudget: async payload => {
    const response = await api.budget.deleteBudget(payload)
    return response
  },
}
