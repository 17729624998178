// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
// import dbCustomer from '@/models/customer'
// eslint-disable-next-line import/no-extraneous-dependencies
import allLocales from '@fullcalendar/core/locales-all'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { isMobile } from 'mobile-device-detect'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import dbAgenda from '@/models/agenda'
import i18n from '@/libs/i18n'
import store from '@/store'

export default function userCalendar() {
  // IsMobile
  const isMovil = isMobile
  const dayCalendarHeader = isMovil ? 'short' : 'long'

  // Use toast
  const toast = useToast()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null
  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    appointment: 'danger',
    task: 'success',
    different: 'warning',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  let today = new Date()
  const d = String(today.getDate()).padStart(2, '0')
  const m = String(today.getMonth() + 1).padStart(2, '0')
  const y = today.getFullYear()

  // eslint-disable-next-line prefer-template
  today = d + '-' + m + '-' + y
  const blankEvent = {
    date: today,
    start_time: '',
    end_time: '',
    type: '',
    description: '',
    comments: '',
    sendEmail: false,
    customerId: null,
    createdUsersId: null,
    assignedUsersId: null,
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  async function updateDraggedEvent(currentEvent) {
    // eslint-disable-next-line no-underscore-dangle
    const eventData = (await dbAgenda.getById(currentEvent._def.extendedProps.agenda_id)).data
    // eslint-disable-next-line no-underscore-dangle
    const currentUser = JSON.parse(window.localStorage.userData)
    // eslint-disable-next-line no-underscore-dangle
    const dateAppointment = currentEvent._instance.range.end.toISOString()
    const startTime = currentEvent.start.toString().substring(16, 21)
    const endTime = currentEvent.end.toString().substring(16, 21)
    eventData.date = `${dateAppointment.toString().substring(0, 4)}-${dateAppointment.toString().substring(5, 7)}-${dateAppointment.toString().substring(8, 10)}`
    eventData.start_time = startTime
    eventData.end_time = endTime
    if (eventData.customer != null) {
      eventData.customerId = eventData.customer.id
      delete eventData.customer
      eventData.providersId = null
      eventData.createdUsersId = currentUser.id
      eventData.agendaId = eventData.agenda_id
    } else {
      eventData.providersId = eventData.provider.id
      delete eventData.provider
      eventData.customerId = null
      eventData.createdUsersId = currentUser.id
      eventData.agendaId = eventData.agenda_id
    }

    try {
      await dbAgenda.update(eventData)
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('agenda.success_update'),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    } catch (e) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t('agenda.error_update'),
          icon: 'XIcon',
          variant: 'danger',
        },
      })
    }
  }

  const updateEventInCalendar = (updatedEventData, propsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Updated',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end)
  }

  /* const sendAppointmentCancelled = async eventId => {
    await dbAgenda.sendAppointmentCancelled(eventId)
  } */

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Event Removed',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    // sendAppointmentCancelled(eventId)
    calendarApi.getEventById(eventId).remove()
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const obj = {}
    // eslint-disable-next-line no-underscore-dangle
    const props = eventApi._def.extendedProps
    Object.keys(props).forEach(key => {
      obj[key] = props[key]
    })
    const day = props.start_date.substring(0, 2)
    const month = props.start_date.substring(3, 5)
    const year = props.start_date.substring(6, 10)
    const start = props.start_date.slice(11)
    const end = props.end_date.slice(11)

    const date = `${day}-${month}-${year}`
    obj.date = date
    obj.start_time = start
    obj.end_time = end
    delete obj.start_date
    delete obj.end_date
    obj.type = (obj.type.toLowerCase()).charAt(0).toUpperCase() + obj.type.toLowerCase().slice(1)
    obj.customer = { id: props.customer_id }
    obj.assignedUsersId = props.assigned_user_id
    obj.id = props.agenda_id
    return obj
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = eventData => {
    store.dispatch('calendar/updateEvent', { event: eventData }).then(response => {
      const updatedEvent = response.data.event

      const propsToUpdate = ['id', 'title', 'type', 'observations', 'description', 'sendEmail', 'customerId', 'createdUsersId', 'assignedUsersId']

      updateEventInCalendar(updatedEvent, propsToUpdate)
      // eslint-disable-next-line no-use-before-define
      refetchEvents()
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = () => {
    const eventId = event.value.agenda_id
    store.dispatch('calendar/removeEvent', { id: eventId }).then(() => {
      removeEventInCalendar(eventId)
    })
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = () => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  // ------------------------------------------------
  // selectedMechanic
  // ------------------------------------------------
  const selectedMechanic = computed(() => store.state.calendar.selectedMechanic)

  // ------------------------------------------------
  // customerOptions
  // ------------------------------------------------
  // const customerOptions = computed(() => store.state.calendar.customerOptions)

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  watch(selectedMechanic, () => {
    refetchEvents()
  })

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', {
        calendars: selectedCalendars.value,
        mechanic: selectedMechanic.value,
      })
      .then(response => {
        successCallback(response.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching calendar events',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  /* const fetchCustomers = () => {
    store.dispatch('calendar/fetchCustomers')
  } */

  const fetchAdmins = () => {
    store.dispatch('calendar/fetchAdmins')
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  let viewAgenda = ''
  let defaultView = ''
  const actualUser = JSON.parse(window.localStorage.userData)
  switch (actualUser.role) {
    case 'ADMIN':
      viewAgenda = 'timeGridWeek,listWeek'
      defaultView = 'timeGridWeek'
      break
    case 'ADMINISTRATION':
      viewAgenda = 'timeGridWeek,listWeek'
      defaultView = 'timeGridWeek'
      break
    case 'MECHANICS':
      viewAgenda = 'listWeek'
      defaultView = 'listWeek'
      break
    default:
      viewAgenda = []
      break
  }
  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: defaultView,
    headerToolbar: {
      start: 'sidebarToggle, prev, next',
      center: 'title',
      end: viewAgenda,
    },
    views: {
      timeGridWeek: {
        titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
        dayHeaderFormat: { day: 'numeric', weekday: dayCalendarHeader },
      },
      listWeek: { // name of view
        titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
        // other view-specific options here
      },
    },
    allDaySlot: false,
    hiddenDays: [0, 6],
    businessHours: {
      daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
      startTime: '08:00',
      endTime: '19:00',
    },
    titleFormat: {
      hour12: false,
    },
    events: fetchEvents,
    eventDidMount(info) {
      if (info.event.extendedProps.status === 'done') {
        // Change background color of row
        // eslint-disable-next-line no-param-reassign
        info.el.style.backgroundColor = 'red'
        // Change color of dot marker
        const dotEl = info.el.getElementsByClassName('fc-event-dot')[0]
        if (dotEl) {
          dotEl.style.backgroundColor = 'white'
        }
      }
    },
    firstDay: 1,
    locales: allLocales,
    nowIndicator: true,
    locale: i18n.locale === 'es' ? 'es-ES' : i18n.locale,
    slotMinTime: '08:00:00',
    slotMaxTime: '20:00:00',
    slotDuration: '00:30:00',
    height: 850,
    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,
    eventStartEditable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
     Allow events’ durations to be editable through resizing.
     ? Docs: https://fullcalendar.io/docs/eventDurationEditable
   */
    eventDurationEditable: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,
    eventResourceEditable: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 50,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      const colorName = calendarsColor[calendarEvent._def.extendedProps.type.toLowerCase()]

      return [
        // Background Color
        `bg-light-${colorName}`,
      ]
    },
    async eventClick({ event: clickedEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      // const response = await dbCustomer.get({ paginate: false })
      event.value = grabEventDataFromEventApi(clickedEvent)
      const options = store.state.calendar.calendarOptions
      event.value.type = options.find(e => e.value.toUpperCase() === event.value.type.toUpperCase())

      // eslint-disable-next-line no-use-before-define
      isEventHandlerModalActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      event.value.date = info.date
      event.value.startTimeHour = info.date.getHours()
      event.value.startTimeMinute = info.date.getMinutes()
      // eslint-disable-next-line no-use-before-define
      isEventHandlerModalActive.value = true
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateDraggedEvent(droppedEvent)
      // updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateDraggedEvent(resizedEvent)
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const isEventHandlerModalActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    // customerOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    // fetchCustomers,
    fetchAdmins,

    // ----- UI ----- //
    isEventHandlerModalActive,
  }
}
