<template>
  <div>
    <b-modal
      id="bv-modal-add-event"
      size="lg"
      hide-footer
      hide-header
      no-close-on-backdrop
      hide-on-blur
      :visible="isEventHandlerModalActive"
      @change="(val) => handleModalChange(val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center py-1">
          <h5 class="mb-0">
            {{ eventLocal.agenda_id ? $t('common.update') : $t('common.add') }} {{ $t('common.event') }}
          </h5>

          <div>
            <feather-icon
              v-if="eventLocal.agenda_id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="deleteDialog = true"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide(); resetCustomer()"
            />
          </div>
        </div>

        <hr>

        <!-- Body -->
        <validation-observer ref="refFormObserver">
          <!-- If the event is for customers -->
          <div v-if="!forProvider">
            <!-- Header: Personal Info -->
            <div class="d-flex">
              <feather-icon
                icon="UserIcon"
                size="19"
              />
              <h4 class="mb-1 ml-50">
                {{ $t('budget.client') }}
              </h4>
            </div>

            <b-row v-if="eventLocal.agenda_id !== undefined">
              <b-col md="2">
                <h5>Name</h5>
                <p>
                  <b-link
                    class="font-weight-bold"
                    target="_blank"
                    :to="{
                      name: 'new-customer',
                      params: { id: event.customer_id },
                    }"
                  >{{ event.customer_name }}
                  </b-link>
                </p>
              </b-col>
              <b-col md="5">
                <h5>Adres</h5>
                <p>{{ event.address }}, {{ event.house_number }} - {{ event.cp }} {{ event.city }} </p>
              </b-col>
              <b-col md="5">
                <h5>Contact</h5>
                <p v-if="!event.email.includes('@slotenmakerdrs.nl')">{{ event.phone }}  |  {{ event.email }}</p>
                <p v-else>{{ event.phone }}</p>
              </b-col>
            </b-row>

            <!-- Customer/SendEmail Row -->
            <b-row
              v-if="eventLocal.agenda_id === undefined"
              class="justify-content-space-around"
              align-v="center"
            >
              <!-- Customer -->
              <b-col sm="8">
                <validation-provider
                  #default="validationContext"
                  name="Customer"
                >
                  <b-form-group
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="eventLocal.customer"
                      :disabled="eventLocal.agenda_id !== undefined"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="customersOpt"
                      input-id="customer"
                      :clearable="false"
                      :filterable="false"
                      :placeholder="$t('agenda.search_customer')"
                      @input="showFormCustomer = true; setCustomer(customer)"
                      @search="onSearch"
                    >
                      <template #option="{ name, city }">
                        <span> {{ name }} - ({{ city }})</span>
                      </template>

                      <template #selected-option="{ name }">
                        <span> {{ name }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col sm="4">
                <validation-provider
                  #default="validationContext"
                  name="Customer"
                >
                  <b-form-group
                    :state="getValidationState(validationContext)"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                      @click="setNewCustomer"
                    >
                      + {{ $t('agenda.new_customer') }}
                    </b-button>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <div v-if="showFormCustomer">
              <validation-provider
                #default="validationContext"
                name="Name"
                :rules="{
                  required: true,
                }"
              >
                <b-form-group :label="$t('budget.name')">
                  <b-form-input
                    v-model="customer.name"
                    :readonly="eventLocal.agenda_id !== undefined"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('budget.name')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <b-row
                class="justify-content-space-around"
                align-v="center"
              >
                <b-col sm="6">
                  <b-form-group :label="$t('customer.cp')">
                    <b-form-input
                      v-model="customer.cp"
                      :readonly="eventLocal.agenda_id !== undefined"
                      trim
                      :placeholder="$t('customer.cp')"
                    />
                  </b-form-group>
                </b-col>

                <b-col sm="6">
                  <!-- House Number -->
                  <b-form-group
                    :label="$t('customer.house')"
                  >
                    <validation-provider
                      name="House"
                    >
                      <b-form-input
                        id="form-house"
                        v-model="customer.house_number"
                        :readonly="eventLocal.agenda_id !== undefined"
                        :placeholder="$t('customer.house')"
                        @input="changeAddress"
                      />
                    </validation-provider>
                  </b-form-group>

                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group :label="$t('customer.city')">
                    <b-form-input
                      v-model="customer.city"
                      :readonly="eventLocal.agenda_id !== undefined"
                      trim
                      :placeholder="$t('customer.city')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                class="justify-content-space-around"
                align-v="center"
              >
                <b-col sm="12">
                  <b-form-group :label="$t('customer.address')">
                    <b-form-input
                      v-model="customer.address"
                      :readonly="eventLocal.agenda_id !== undefined"
                      trim
                      :placeholder="$t('customer.address')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                class="justify-content-space-around"
                align-v="center"
              >
                <b-col sm="6">
                  <validation-provider
                    #default="validationContext"
                    name="Email"
                    :rules="{
                      required: false,
                      email: true,
                    }"
                  >
                    <b-form-group :label="$t('budget.email')">
                      <b-form-input
                        v-model="customer.email"
                        :readonly="eventLocal.agenda_id !== undefined"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('budget.email')"
                        @blur="checkCustomerEmail(customer.email)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                      <span
                        v-if="customerRepeatedEmail"
                        class="text-danger"
                        style="font-size:0.857rem"
                      >{{ $t('agenda.attention_email') }}</span>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col sm="6">
                  <validation-provider
                    #default="validationContext"
                    name="Phone"
                    :rules="{
                      required: true
                    }"
                  >
                    <b-form-group :label="$t('budget.phone')">
                      <b-form-input
                        v-model="customer.phone"
                        :readonly="eventLocal.agenda_id !== undefined"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="$t('budget.phone')"
                        @blur="checkCustomerPhone(customer.phone)"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                      <span
                        v-if="customerRepeatedPhone"
                        class="text-danger"
                        style="font-size:0.857rem"
                      >{{ $t('agenda.attention_phone') }}</span>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </div>

          <!--- If the event is for providers -->
          <div v-else>
            <!-- Header: Personal Info -->
            <div class="d-flex">
              <feather-icon
                icon="UserIcon"
                size="19"
              />
              <h4 class="mb-0 ml-50">
                {{ $t('agenda.provider') }}
              </h4>
            </div>
            <!-- Provider/SendEmail Row -->
            <b-row
              class="justify-content-space-around"
              align-v="center"
            >
              <!-- Provider -->
              <b-col sm="12">
                <validation-provider
                  #default="validationContext"
                  name="provider"
                >
                  <b-form-group
                    :label="$t('agenda.provider')"
                    label-for="provider"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="provider"
                      :disabled="eventLocal.agenda_id !== undefined"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="providers"
                      :get-option-label="(provider) => provider.name"
                      input-id="provider"
                      :clearable="false"
                      @input="setCustomer(provider)"
                      @search="onSearch"
                    >
                      <template #option="{ name }">
                        <span> {{ name }}</span>
                      </template>

                      <template #selected-option="{ name }">
                        <span> {{ name }}</span>
                      </template>
                    </v-select>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Send Email -->
              <!-- <b-col
                sm="3"
                class="mt-1"
              >
                <b-form-group>
                  <b-form-checkbox
                    v-model="sendEmailProvider"
                    :checked="sendEmailProvider"
                    name="check-button"
                    switch
                    inline
                  >
                    {{ $t('agenda.send_email') }}
                  </b-form-checkbox>
                </b-form-group>
              </b-col> -->
            </b-row>
          </div>

          <hr class="mb-2">

          <b-row>
            <b-col md="6">
              <div class="d-flex">
                <feather-icon
                  icon="ToolIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  {{ $t('agenda.vendors') }}
                </h4>
              </div>
              <!-- Customer/SendEmail Row -->
              <b-row
                class="justify-content-space-around"
                align-v="center"
              >
                <b-col>
                  <validation-provider
                    #default="validationContext"
                    name="Mechanics"
                    rules="required"
                  >
                    <b-form-group
                      :label="$t('agenda.mechanics')"
                      label-for="mechanics"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="mechanic"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="mechanics"
                        :get-option-label="(mechanic) => mechanic.name"
                        input-id="admin"
                      >
                        <template #option="{ name }">
                          <span> {{ name }}</span>
                        </template>

                        <template #selected-option="{ name }">
                          <span> {{ name }}</span>
                        </template>
                      </v-select>

                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <!-- Type -->
            <b-col md="6">
              <div class="d-flex">
                <feather-icon
                  icon="ClockIcon"
                  size="19"
                />
                <h4 class="mb-0 ml-50">
                  {{ $t('budget.details') }}
                </h4>
              </div>
              <validation-provider
                #default="validationContext"
                name="Type"
                rules="required"
              >
                <b-form-group
                  :label="$t('agenda.type')"
                  label-for="type"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="calendarOptions"
                    label="label"
                    itemid="value"
                    :reduce="(type) => type.label"
                    input-id="calendar"
                    @option:clear="setNewCustomer"
                  >
                    <template #option="{ color, label }">
                      <div
                        class="rounded-circle d-inline-block mr-50"
                        :class="`bg-${color}`"
                        style="height: 10px; width: 10px"
                      />
                      <span> {{ label }}</span>
                    </template>

                    <template #selected-option="{ color, label }">
                      <div
                        class="rounded-circle d-inline-block mr-50"
                        :class="`bg-${color}`"
                        style="height: 10px; width: 10px"
                      />
                      <span> {{ label }}</span>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- Form -->
          <b-form
            @submit.prevent=""
            @reset.prevent="resetForm"
          >
            <b-row
              class="justify-content-center"
              align-v="center"
            >
              <!-- Date -->
              <b-col md="4">
                <b-form-group
                  :label="$t('agenda.date')"
                  label-for="date"
                >
                  <flat-pickr
                    v-model="eventLocal.date"
                    class="form-control"
                    :config="{
                      enableTime: false,
                      dateFormat: 'd-m-Y'
                    }"
                  />
                </b-form-group>
              </b-col>
              <!--- Start Time --->
              <b-col
                md="2"
                class="pr-0"
              >
                <validation-provider
                  #default="validationContext"
                  name="Hour"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('common.start_time')"
                    label-for="startHour"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-select
                      v-model="startHour"
                      :options="hours"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
                class="pl-0"
              >
                <validation-provider
                  #default="validationContext"
                  name="Minutes"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('common.end_minutes')"
                    label-for="Minutes"
                    :state="getValidationState(validationContext)"
                    class="label-minutes"
                  >
                    <b-form-select
                      v-model="startMinute"
                      :options="minutes"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!--- End Time --->
              <b-col
                md="2"
                class="pr-0"
              >
                <validation-provider
                  #default="validationContext"
                  name="Hour"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('common.end_time')"
                    label-for="endHour"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-select
                      v-model="endHour"
                      :options="hours"
                      :value="endHour ? endHour : oneHourMore(startHour)"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                md="2"
                class="pl-0"
              >
                <validation-provider
                  #default="validationContext"
                  name="endMinutes"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('common.end_minutes')"
                    label-for="Minutes"
                    :state="getValidationState(validationContext)"
                    class="label-minutes"
                  >
                    <b-form-select
                      v-model="endMinute"
                      :options="minutes"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- Textarea -->
            <b-form-group
              :label="$t('agenda.description')"
              label-for="event-description"
            >
              <b-form-textarea
                id="event-description"
                v-model="eventLocal.description"
              />
            </b-form-group>

            <!-- Textarea -->
            <b-form-group
              :label="$t('agenda.comments')"
              label-for="event-comments"
            >
              <b-form-textarea
                id="event-comments"
                v-model="eventLocal.comments"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="save()"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-25"
                />
                {{ eventLocal.agenda_id ? $t('common.update') : $t('common.add') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="resetCustomer"
              >
                {{ $t('common.reset') }}
              </b-button>
              <b-button
                v-if="!forProvider"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :variant="eventLocal.agenda_id ? 'primary' : 'secondary'"
                class="ml-auto"
                @click="confirmSendBudget"
              >
                {{ $t('agenda.generate') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>

    <!-- Delete Confirm Modal -->
    <b-modal
      id="bv-modal-confirm-delete-event"
      hide-footer
      hide-header
      :visible="deleteDialog"
    >
      <template #default="{ }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">
            {{ $t('agenda.delete_event') }}
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="deleteDialog = false"
            />
          </div>
        </div>

        <hr>

        <!-- Body -->
        <validation-observer ref="refFormObserver">
          <div>{{ $t('agenda.delete_text') }}</div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="ml-auto"
              @click="deleteDialog = false"
            >
              {{ $t('common.cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-1"
              @click="removeEvent(); deleteDialog = false"
            >
              {{ $t('common.delete') }}
            </b-button>
          </div>
        </validation-observer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BFormTextarea, BButton, BFormInvalidFeedback, BLink, BModal, BRow, BCol, BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, url, numeric,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import dbCustomers from '@/models/customer'
import dbAgenda from '@/models/agenda'
import dbBudget from '@/models/budget'
import dbProvider from '@/models/provider'
import dbUser from '@/models/user'
import geoLocation from '@/models/geoLocation'
import generateToken from '@/auth/geoAuth'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BRow,
    BCol,
    BModal,
    BLink,
    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerModalActive',
    event: 'update:is-event-handler-modal-active',
  },
  props: {
    isEventHandlerModalActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
    forProvider: {
      type: Boolean,
    },
  },
  data() {
    return {
      provider: {},
      customersOpt: [],
      search: '$$',
      offset: 0,
      limit: 10,
      providers: [],
      context: null,
      loading: false,
      customerRepeatedEmail: false,
      customerRepeatedPhone: false,
      startHour: '',
      // eslint-disable-next-line radix
      endHour: '',
      startMinute: '',
      endMinute: '00',
      hours: ['08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18'],
      minutes: ['00', '30'],
      labels: {
        nl: {
          labelHours: 'Uren',
          labelMinutes: 'Minuten',
          labelSeconds: 'Seconden',
          labelIncrement: 'Increment',
          labelDecrement: 'Verlagen',
          labelNoTimeSelected: 'tijd',
          labelCloseButton: 'Dichtbij',
        },
        en: {
          labelHours: 'Hours',
          labelMinutes: 'Minutes',
          labelSeconds: 'Seconds',
          labelIncrement: 'Increment',
          labelDecrement: 'Decrement',
          labelNoTimeSelected: 'Time',
          labelCloseButton: 'Close',
        },
      },
      required,
      email,
      url,
      numeric,
      deleteDialog: false,
      mechanic: null,
      customer: {
        id: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        city: '',
        cp: '',
      },
      fillAddress: {},
      sendEmailProvider: null,
      idAppointment: '',
      showFormCustomer: false,
      isNewCustomer: false,
    }
  },
  computed: {
    mechanics() {
      const arr = this.$store.state.app.system?.users_map
      const mapMechanics = new Map()
      arr.MECHANICS.forEach(obj => {
        Object.keys(obj).forEach(key => {
          mapMechanics.set(key, obj[key])
        })
      })
      const mapFreelancers = new Map()
      arr.FREELANCERS.forEach(obj => {
        Object.keys(obj).forEach(key => {
          mapFreelancers.set(key, obj[key])
        })
      })

      const arrayMechanics = []
      const arrayFreelancers = []
      mapMechanics.forEach((val, key) => {
        arrayMechanics.push({ id: Number(key), name: val, role: 'MECHANICS' })
      })
      mapFreelancers.forEach((val, key) => {
        arrayFreelancers.push({ id: Number(key), name: `${val} (Freelance)`, role: 'FREELANCERS' })
      })
      // eslint-disable-next-line
      const array = arrayMechanics.concat(arrayFreelancers)

      return array
    },
  },
  watch: { /*
    // eslint-disable-next-line object-shorthand
    'eventLocal.customer.id'() {
      if (this.eventLocal) this.setCustomer()
    }, */
    // eslint-disable-next-line object-shorthand
    'eventLocal.assignedUsersId'() {
      this.mechanic = this.mechanics.find(mec => mec.id === this.eventLocal.assignedUsersId)
    },
    // eslint-disable-next-line object-shorthand
    'eventLocal.start_time'() {
      this.startHour = this.eventLocal.start_time.substring(0, 2)
      this.startMinute = this.eventLocal.start_time.substring(3)
    },
    // eslint-disable-next-line object-shorthand
    'eventLocal.end_time'() {
      this.endHour = this.eventLocal.end_time.substring(0, 2)
      this.endMinute = this.eventLocal.end_time.substring(3)
    },
  },
  async beforeUpdate() {
    this.provider = {}
    if (this.isEventHandlerModalActive) {
      if (this.event.agenda_id) {
        const event = (await dbAgenda.getById(this.event.agenda_id)).data
        this.provider = event.provider
        if (this.provider != null) {
          this.$emit('updateEventType', true)
        } else {
          this.$emit('updateEventType', false)
        }
      }
    }
    if (this.event.startTimeHour !== undefined) this.startHour = this.event.startTimeHour
    if (this.event.startTimeHour !== undefined) this.startMinute = this.event.startTimeMinute === 0 ? '00' : this.event.startTimeMinute
  },
  methods: {
    async searchCustomers() {
      const response = await dbProvider.get({ page: 1, perPage: this.perPage, paginate: false })
      const result = await dbCustomers.get({
        page: 1, perPage: this.perPage, paginate: false, word: this.search,
      })
      this.providers = response
      this.customersOpt = result
    },
    checkCustomerEmail(val) {
      if (Object.keys(this.eventLocal.customer) < 1) {
        if (this.customersOpt.find(client => client.email === val)) {
          this.customerRepeatedEmail = true
        } else {
          this.customerRepeatedEmail = false
        }
      } else {
        this.customerRepeatedEmail = false
      }
    },
    checkCustomerPhone(val) {
      if (Object.keys(this.eventLocal.customer) < 1) {
        if (this.customersOpt.find(client => client.phone === val)) {
          this.customerRepeatedPhone = true
        } else {
          this.customerRepeatedPhone = false
        }
      } else {
        this.customerRepeatedPhone = false
      }
    },
    onSearch(query) {
      this.search = query
      if (this.search.length > 2) this.searchCustomers()
      this.offset = 0
    },
    onContext(ctx) {
      this.context = ctx
    },
    changeAddress() {
      const payload = { cp: this.customer.cp, house_number: this.customer.house_number }
      payload.cp = payload.cp.replace(/[^a-zA-Z0-9]/g, '')
      payload.house_number = payload.house_number.replace(/[^0-9]/g, '')
      if (payload.cp.length === 9 || payload.cp.length === 6) {
        if (payload.house_number.length > 0) {
          this.getLocation(payload)
        }
      }
    },
    async getLocation(info) {
      const bearer = await generateToken()
      const payload = {
        cp: info.cp,
        house_number: info.house_number,
        bearer,
      }
      this.fillAddress = await geoLocation.get(payload)
      this.customer.address = this.fillAddress.street
      this.customer.city = this.fillAddress.city
      this.customer.latitude = this.fillAddress.latitude
      this.customer.longitude = this.fillAddress.longitude
    },
    confirmSendBudget() {
      this.$bvModal.msgBoxConfirm(this.$t('agenda.generate_quote_text'), {
        title: this.$t('agenda.generate_quote'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) this.sendBudget()
        })
    },
    async sendBudget() {
      const valid = await this.$refs.refFormObserver.validate()

      if (valid) {
        this.eventLocal.customer.customerId = this.eventLocal.customer.id
        delete this.eventLocal.customer.id
        const dateWithoutAppointment = this.eventLocal.date ? moment(this.eventLocal.date).format('YYYY-MM-DD') : ''
        const dateWithAppointment = this.eventLocal.date ? moment(this.eventLocal.date, 'DD-MM-YYYY').format('YYYY-MM-DD') : ''

        const payload = {
          customer: this.eventLocal.customer || {},
          mechanicUsername: this.mechanic.name || '',
          concept: 'test',
          date: this.eventLocal.agenda_id ? dateWithAppointment : dateWithoutAppointment,
          status: 'PENDING',
          products: [],
          remark: this.eventLocal.comments,
        }

        try {
          const { data } = await dbBudget.set(payload)
          this.$router.push(`/budgets/edit/${data.id}`)
        } catch (error) {
          /* eslint-disable-next-line no-console */
          console.error(error)
        }
      }
    },
    async save() {
      const valid = await this.$refs.refFormObserver.validate()

      if (valid) {
        if (!this.forProvider) {
          try {
            const customerResponse = await this.handleCustomer()
            const currentUser = JSON.parse(window.localStorage.userData)
            const finalObj = JSON.parse(JSON.stringify(this.eventLocal))
            finalObj.customerId = customerResponse.id !== undefined ? customerResponse.id : null
            delete finalObj.customer
            finalObj.assignedUsersId = this.mechanic.id
            finalObj.type = (this.calendarOptions.find(e => e.label === finalObj.type))?.value ?? finalObj.type.value
            finalObj.type = finalObj.type.toUpperCase()
            finalObj.start_time = `${this.startHour}:${this.startMinute}`
            finalObj.end_time = `${this.endHour}:${this.endMinute}`
            // const dateParts = finalObj.date.split('-')
            // const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
            finalObj.date = this.eventLocal.date
            finalObj.providersId = null

            finalObj.createdUsersId = currentUser.id
            if (finalObj.agenda_id) finalObj.agendaId = finalObj.agenda_id
            await dbAgenda.create(finalObj)
            this.idAppointment = finalObj.agenda_id
            this.$emit('add-event')
            this.resetCustomer()
            if (this.mechanic.role === 'MECHANICS') {
              await this.sendEmailAppointment()
            } else {
              await this.confirmSendWhatsapp(this.mechanic.id, finalObj)
            }
          } catch (error) {
            /* eslint-disable-next-line no-console */
            console.error(error)
          }
        } else {
          const currentUser = JSON.parse(window.localStorage.userData)
          const finalObj = JSON.parse(JSON.stringify(this.eventLocal))
          finalObj.provider = this.provider
          finalObj.providersId = finalObj.provider.id
          finalObj.customerId = null
          finalObj.assignedUsersId = this.mechanic.id
          finalObj.type = (this.calendarOptions.find(e => e.label === finalObj.type))?.value ?? finalObj.type.value
          finalObj.type = finalObj.type.toUpperCase()
          finalObj.start_time = `${this.startHour}:${this.startMinute}`
          finalObj.end_time = `${this.endHour}:${this.endMinute}`

          // remove providers field
          delete finalObj.provider

          // const dateParts = finalObj.date.split('-')
          // const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
          finalObj.date = this.eventLocal.date
          // finalObj.date = ((dateObject.getTime()) / 1000).toString()
          finalObj.createdUsersId = currentUser.id
          if (finalObj.agenda_id) finalObj.agendaId = finalObj.agenda_id
          await dbAgenda.create(finalObj)
          this.idAppointment = finalObj.agenda_id
          this.$emit('add-event')
        }
      }
    },
    sendEmailAppointment() {
      this.$bvModal.msgBoxConfirm(this.$t('agenda.box_message.send_email_body'), {
        title: this.$t('agenda.box_message.send_email_title'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) dbAgenda.sendAppointment(this.idAppointment)
        })
    },
    confirmSendWhatsapp(idFreelance, eventDay) {
      this.$bvModal.msgBoxConfirm(this.$t('agenda.box_message_wa.send_email_body'), {
        title: this.$t('agenda.box_message_wa.send_email_title'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            this.$bvModal.msgBoxConfirm(this.$t('budget.modal.whatsapp_message_body'), {
              title: this.$t('budget.modal.whatsapp_message_title'),
              size: 'sm',
              okVariant: 'primary',
              okTitle: this.$t('budget.modal.whatsapp_complete'),
              cancelTitle: this.$t('budget.modal.whatsapp_basic'),
              cancelVariant: 'outline-secondary',
              hideHeaderClose: false,
              centered: true,
            })
              .then(ok => {
                if (ok) {
                  this.sendWhatsAppFreelance(idFreelance, eventDay, 'long')
                } else {
                  this.sendWhatsAppFreelance(idFreelance, eventDay, 'short')
                }
              })
          }
        })
    },
    async sendWhatsAppFreelance(idFreelance, eventDay, typeMessage) {
      const Freelance = await dbUser.getById({ userId: idFreelance })
      const Customer = await dbCustomers.getById(eventDay.customerId)
      if (`${Freelance.phone}`) {
        moment.lang('nl')
        const Event = JSON.parse(JSON.stringify(eventDay))
        const date = Event.date.length < 14
          ? moment(Event.date, 'DD-MM-YYYY').format('DD MMMM YYYY')
          : moment(Event.date).format('DD MMMM YYYY')
        const messageLong = `Hallo ${Freelance.name}. We sturen je de volgende klant. Graag z.s.m. contact opnemen.:%0A%0A
*Datum*: ${date}%0A
*Naam*: ${Customer.name}%0A
*Adres*: ${Customer.address}, ${Customer.house_number}%0A
*Woonplaats*: ${Customer.cp} - ${Customer.city}%0A
*Telefoon*: ${Customer.phone}%0A
*Opmerkingen*: ${Event.description}%0A
*Locatie*: https://www.google.nl/maps?q=${Customer.latitude},${Customer.longitude}`
        const messageShort = `Hallo ${Freelance.name}. We sturen je de volgende klant. Graag z.s.m. contact opnemen.:%0A%0A
*Naam*: ${Customer.name}%0A
*Woonplaats*:  ${Customer.cp} - ${Customer.city}%0A
*Telefoon*: ${Customer.phone}%0A
*Opmerkingen*: ${Event.description}`
        const message = typeMessage === 'long' ? messageLong : messageShort
        window.open(`https://api.whatsapp.com/send/?phone=${Freelance.phone}&text=${message}`, 'WhatsApp', 'width=200, height=200')
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Kennisgeving',
            icon: 'InfoIcon',
            text: 'De freelancer moet een telefoonnummer hebben om het bericht te kunnen versturen.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    async handleCustomer() {
      this.isNewCustomer = false
      if (Object.keys(this.eventLocal.customer) < 1) this.isNewCustomer = true
      if (!this.isNewCustomer) {
        this.customer.id = this.eventLocal.customer.id
        this.customer.customerId = this.customer.id
        if (this.eventLocal.agenda_id === undefined) await dbCustomers.save(this.customer)
        return this.customer
      }
      this.customer.customerId = null
      this.customer.id = null

      this.customer.email = this.customer.email !== ''
        ? this.customer.email
        : `${this.customer.cp.replace(/ /g, '')}${this.customer.house_number.replace(/ /g, '')}@slotenmakerdrs.nl`
      return dbCustomers.save(this.customer)
    },
    setCustomer() {
      if (this.eventLocal.customer) {
        this.customer.name = this.eventLocal.customer.name
        this.customer.email = this.eventLocal.customer.email
        this.customer.phone = this.eventLocal.customer.phone
        this.customer.city = this.eventLocal.customer.city
        this.customer.address = this.eventLocal.customer.address
        this.customer.cp = this.eventLocal.customer.cp
        this.customer.house_number = this.eventLocal.customer.house_number
        this.isNewCustomer = false
      }
    },
    setNewCustomer() {
      this.isNewCustomer = !this.isNewCustomer
      this.eventLocal.customer = ''
      this.showFormCustomer = true
      this.customer.name = ''
      this.customer.email = ''
      this.customer.phone = ''
      this.customer.city = ''
      this.customer.address = ''
      this.customer.cp = ''
      this.customer.house_number = ''
    },
    resetCustomer() {
      this.customer.name = ''
      this.customer.email = ''
      this.customer.phone = ''
      this.customer.city = ''
      this.customer.address = ''
      this.customer.cp = ''
      this.customer.house_number = ''
      this.isNewCustomer = true
      this.showFormCustomer = false
      this.eventLocal.customer = {}
    },
    removeEvent(id) {
      this.$emit('remove-event')
      this.$emit('update:is-event-handler-modal-active', false)
      this.$bvModal.msgBoxConfirm(this.$t('agenda.box_message.send_email_body_cancel_date'), {
        title: this.$t('agenda.box_message.send_email_title_cancel_date'),
        size: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('budget.modal.Yes'),
        cancelTitle: this.$t('budget.modal.No'),
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) dbAgenda.sendAppointmentCancelled(id)
        })
    },
    handleModalChange(val) {
      this.$emit('update:is-event-handler-modal-active', val)
      this.resetCustomer()
    },
    oneHourMore(time) {
      // eslint-disable-next-line radix
      this.endHour = (parseInt(time) + 1).toString()
      return this.endHour
    },
  },
  setup(props) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,
      // customerOptions,
      // customers,
      newCustomer,
      // fetchCustomers,
      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    return {
      // Add New Event
      eventLocal,
      // fetchCustomers,
      calendarOptions,
      // customerOptions,
      // customers,
      onSubmit,
      guestsOptions,
      newCustomer,
      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
<style>
  .label-minutes label {
    color: white;
  }
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.add-new-client-header {
  padding: $options-padding-y $options-padding-x;
  color: $success;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
</style>
<style scoped>
  .pagination {
    display: flex;
    margin: 0.25rem 0.25rem 0;
  }
  .pagination button {
    flex-grow: 1;
  }
  .pagination button:hover {
    cursor: pointer;
  }
</style>
