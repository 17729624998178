import api from '@/api/index'

export default {
  getById: async payload => {
    const response = await api.agenda.getById(payload)
    return response
  },
  get: async payload => {
    const response = await api.agenda.get(payload)
    return response
  },
  create: async payload => {
    const response = await api.agenda.create(payload)
    return response
  },
  update: async payload => {
    const response = await api.agenda.update(payload)
    return response
  },
  delete: async payload => {
    const response = await api.agenda.delete(payload)
    return response
  },
  sendAppointment: async payload => {
    const response = await api.agenda.sendAppointment(payload)
    return response
  },
  sendAppointmentCancelled: async payload => {
    const response = await api.agenda.sendAppointmentCancelled(payload)
    return response
  },
}
