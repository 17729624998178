import api from '@/api/index'

export default {
  getAll: async payload => {
    const response = await api.user.getAll(payload)
    const { data } = response
    return data
  },
  getAdmins: async payload => {
    const response = await api.user.getAll(payload)
    const { data } = response.data
    return data || response
  },
  getById: async payload => {
    const response = await api.user.getById(payload)
    const { data } = response
    return data
  },
  set: async payload => {
    const response = await api.user.set(payload)
    return response
  },
  confirmAccount: async (payload, token) => {
    const response = await api.user.confirmAccount(payload, token)
    return response
  },
}
