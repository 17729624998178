import axios from '@axios'
// import dbCustomer from '@/models/customer'
import dbUser from '@/models/user'
import dbAgenda from '@/models/agenda'
import i18n from '@/libs/i18n'

export default {
  namespaced: true,
  state: {
    customers: [],
    admins: [],
    calendarOptions: [
      {
        color: 'danger',
        label: `${i18n.t('agenda.appointment')}`,
        value: 'Appointment',
      },
      {
        color: 'success',
        label: `${i18n.t('agenda.task')}`,
        value: 'Task',
      },
      {
        color: 'warning',
        label: `${i18n.t('agenda.different')}`,
        value: 'Different',
      },
    ],
    customerOptions: [],
    selectedCalendars: ['Appointment', 'Task', 'Different'],
    selectedMechanic: { name: 'All', id: null },
    loading: false,
  },
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_SELECTED_MECHANIC(state, val) {
      state.selectedMechanic = val
    },
    setCustomers(state, val) {
      state.customers = val
    },
    setAdmins(state, val) {
      state.admins = val
    },
    setLoading(state, val) {
      state.loading = val
    },
  },
  getters: {
    getCustomersLocations: state => events => {
      const locations = []
      const newData = events.map(item => ({ ...item, selected: false }))
      newData.forEach(event => {
        const hrs = event.start_date.split(' ')[1].split(':')[0]
        // eslint-disable-next-line
        event.sort = hrs
      })
      newData.sort((a, b) => (a.sort - b.sort))
      newData.forEach(event => {
        if (event.customer_id != null || state != null) {
          locations.push(`${event.latitude}, ${event.longitude}`)
        }
      })
      return locations
    },
  },
  actions: {
    async fetchEvents(ctx, { calendars, mechanic }) {
      ctx.commit('setLoading', true)
      const response = await dbAgenda.get()
      const events = response.data
      let aux = []
      events.forEach(ev => {
        const day = ev.start_date.substring(0, 2)
        const month = ev.start_date.substring(3, 5)
        const year = ev.start_date.substring(6, 10)
        const startTime = ev.start_date.slice(11)
        const endTime = ev.end_date.slice(11)
        // eslint-disable-next-line radix
        const mornEvening = parseInt(ev.start_date.slice(11, 13)) < 13 ? '08:00 - 13:00' : '13:00 - 18:00'
        // eslint-disable-next-line prefer-template
        const startDate = year + '-' + month + '-' + day + ' ' + startTime
        // eslint-disable-next-line prefer-template
        const endDate = year + '-' + month + '-' + day + ' ' + endTime
        aux.push(Object.assign(ev, {
          allDay: false,
          title: `${mornEvening} | ${ev.assigned_name}${ev.city ? ` | ${ev.city}` : ` | # ${ev.cityprovider}`}
                  ${ev.comments ? ` - ${ev.comments}` : ''}`,
          start: startDate,
          end: endDate,
          id: ev.agenda_id,
        }))
      })
      // TODO: Filter with selected calendars
      const calendarAux = []
      calendars.forEach(cal => calendarAux.push(cal.toLowerCase()))
      aux = aux.filter(ev => calendarAux.includes(ev.type.toLowerCase()))
      aux = aux.filter(e => e.assigned_user_id === mechanic.id) ?? aux.filter(e => e.assigned_user_id === 0)
      response.data = aux
      ctx.commit('setLoading', false)
      return response
    },
    /* async fetchCustomers({ commit }) {
      try {
        const response = await dbCustomer.get({ paginate: false })
        commit('setCustomers', response)
      } catch (e) {
        /!* eslint-disable-next-line no-console *!/
        console.error(e)
      }
    }, */
    async fetchAdmins({ commit }) {
      function isAdmin(value) {
        return value.role === 'ADMIN'
      }

      try {
        const response = await dbUser.getAdmins({ paginate: false })
        const data = response.data || response
        commit('setAdmins', data.filter(isAdmin))
      } catch (e) {
        /* eslint-disable-next-line no-console */
        console.error(e)
      }
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return dbAgenda.delete(id)
    },
  },
}
