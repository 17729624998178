<template>
  <div>
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{ show: isCalendarOverlaySidebarActive }"
        >
          <calendar-sidebar
            :is-event-handler-modal-active.sync="isEventHandlerModalActive"
            :ref-calendar="refCalendar"
            @forProvider="(value) => { forProvider = value }"
          />
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <b-spinner
                v-if="loading"
                variant="primary"
                label="Spinning"
              />
              <full-calendar
                v-show="!loading"
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </div>

        <!-- Sidebar Overlay -->
        <div
          class="body-content-overlay"
          :class="{ show: isCalendarOverlaySidebarActive }"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <calendar-event-handler
          v-model="isEventHandlerModalActive"
          :event="event"
          :clear-event-data="clearEventData"
          :for-provider="forProvider"
          @updateEventType="(value) => { forProvider = value}"
          @remove-event="removeEvent"
          @add-event="refetchEvents(); isEventHandlerModalActive = !isEventHandlerModalActive"
          @update-event="refetchEvents(); isEventHandlerModalActive = !isEventHandlerModalActive"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    BSpinner,
  },
  data() {
    return {
      forProvider: false,
    }
  },
  computed: {
    loading() {
      return calendarStoreModule.state.loading
    },
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    locale() {
      this.calendarOptions.locale = this.locale === 'en' ? 'en-uk' : this.locale
    },
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      // fetchCustomers,
      fetchAdmins,
      refetchEvents,
      calendarOptions,
      customerOptions,

      // ----- UI ----- //
      isEventHandlerModalActive,
    } = useCalendar()

    fetchEvents()
    // fetchCustomers()
    fetchAdmins()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,
      customerOptions,

      // ----- UI ----- //
      isEventHandlerModalActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
<style>
  .fc-list .fc-list-event-time {
    display: none;
  }
  .fc-event-time {
    background: #f06767;
    color: #fff;
    padding: 3px 6px;
    border-radius: 5px 5px 0 0;
  }
  .fc-v-event .fc-event-title {
    text-indent: -81px;
  }
</style>
