var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loadingRoutes}},[_c('div',{staticClass:"sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1"},[_c('div',{staticClass:"p-2"},[(_vm.userData.role !== 'ADMINISTRATION')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-0",attrs:{"aria-controls":"sidebar-add-new-event","aria-expanded":String(_vm.isEventHandlerModalActive),"variant":"primary","block":""},on:{"click":function($event){_vm.$emit('update:isEventHandlerModalActive', true);
          _vm.$emit('forProvider', false);}}},[_vm._v(" "+_vm._s(_vm.$t("agenda.add_event_customer"))+" ")]):_vm._e(),(_vm.userData.role !== 'ADMINISTRATION')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-3",attrs:{"aria-controls":"sidebar-add-new-event","aria-expanded":String(_vm.isEventHandlerModalActive),"variant":"outline-primary","block":""},on:{"click":function($event){_vm.$emit('update:isEventHandlerModalActive', true);
          _vm.$emit('forProvider', true);}}},[_vm._v(" "+_vm._s(_vm.$t("agenda.add_event_provider"))+" ")]):_vm._e(),_c('div',[_c('h5',{staticClass:"app-label section-label mb-1"},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("common.calendars")))])]),_c('b-form-checkbox',{staticClass:"mb-1",model:{value:(_vm.checkAll),callback:function ($$v) {_vm.checkAll=$$v},expression:"checkAll"}},[_vm._v(" "+_vm._s(_vm.$t("agenda.view_all"))+" ")]),_c('b-form-group',[_c('b-form-checkbox-group',{attrs:{"name":"event-filter","stacked":""},model:{value:(_vm.selectedCalendars),callback:function ($$v) {_vm.selectedCalendars=$$v},expression:"selectedCalendars"}},_vm._l((_vm.calendarOptions),function(item){return _c('b-form-checkbox',{key:item.label,staticClass:"mb-1",class:("custom-control-" + (item.color)),attrs:{"name":"event-filter","value":item.value}},[_vm._v(" "+_vm._s(_vm.$t("agenda." + item.value.toLowerCase()))+" ")])}),1)],1),_c('h5',{staticClass:"app-label section-label mb-1 mt-1"},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("agenda.day")))])]),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: false, dateFormat: 'd-m-Y' }},on:{"input":_vm.setDay},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('h5',{staticClass:"app-label section-label mb-1 mt-1"},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t("agenda.mechanics")))])]),_c('b-form-group',[(_vm.userData.role === 'MECHANICS')?_c('v-select',{attrs:{"readonly":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"get-option-label":function (mechanic) { return mechanic.name; },"clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
          var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
          var name = ref.name;
          var id = ref.id;
return [(id === null)?_c('span',[_vm._v(_vm._s(_vm.$t("agenda.all")))]):_c('span',[_vm._v(" "+_vm._s(name))])]}}],null,false,2567303019),model:{value:(_vm.isMechanic),callback:function ($$v) {_vm.isMechanic=$$v},expression:"isMechanic"}}):_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.mechanics,"get-option-label":function (mechanic) { return mechanic.name; },"clearable":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
          var name = ref.name;
return [_c('span',[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
          var name = ref.name;
          var id = ref.id;
return [(id === null)?_c('span',[_vm._v(_vm._s(_vm.$t("agenda.all")))]):_c('span',[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.selectedMechanic),callback:function ($$v) {_vm.selectedMechanic=$$v},expression:"selectedMechanic"}})],1),_c('h5',{staticClass:"app-label section-label mb-1 mt-1"},[_c('span',{staticClass:"align-middle"},[_vm._v("Routes")])]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"my-1",attrs:{"disabled":_vm.selectedMechanic.name === _vm.$t('agenda.all'),"variant":"outline-primary","block":""},on:{"click":_vm.coordinates}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"MapIcon"}}),_vm._v(" "+_vm._s(_vm.$t("agenda.get_routes"))+" ")],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }